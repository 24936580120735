.container {
  background-color: #ffffff;
//   overflow: auto;
overflow-y: scroll;
  height: 100%;
  :global {
      .ant-input-wrapper {
          background-color: #ffffff;
      }
      .ant-tree-treenode {
          width: 100%;
          .ant-tree-node-content-wrapper {
              width: 100px;
              overflow: auto;
            //   max-width: 150;
            //   overflow: hidden;
            //   white-space: nowrap;
            //   text-overflow: ellipsis;
          }
      }
      .ant-tree{
          max-height: 73vh;
          .title--3TjqE{
            width: 80%;
            display: inline-block;
          }
      }
  }
  .title {
      .titleIcon {
          display: none;
      }
  }
  .title:hover .titleIcon {
      display: block;
  }
}

@primary-color: #64D399;@border-radius-base: 5px;