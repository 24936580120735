.commonStyle {
    overflow: hidden;
    position: relative;
    border: 1px solid #eee;
    counter-reset: reportSubTitleNumber;
    // width: 1000px;
    max-width: 80%;
    min-width: 80%;
    margin: 0 auto;
    font-size: 1.4rem;
    // width: 960px;
    // padding: 20px 0;
}
.header {
    .info {
        position: relative;
        padding-top: 36%;
        padding-bottom: 30%;
        h1 {
            position: relative;
            text-align: center;
            font-size: 3rem;
            line-height: 1.2;
            font-family: '楷体';
            font-weight: bold;
            color: #FFFFFF;
        }
        h2 {
            text-align: center;
            font-size: 1.8rem;
            line-height: 1.2;
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 1px;
            margin-top: 5rem;
            margin-bottom: 16rem;
        }
        dl {
            overflow: hidden;
            position: relative;
            color: #fff;
            font-size: 1.25rem;
            line-height: 40px;
            text-align: left;
            margin: 2rem 0;
            padding-left: 5rem;
            font-family: '微软雅黑';
            dt {
                font-size: 1.25rem;
                line-height: 32px;
                color: #FFFFFF;
                opacity: 1;
            }
        }
    }
}
.section {
    margin-top: 1rem;
    padding: 0 2rem 1rem;
    .reportTitle {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.2;
        padding: 1.2rem 0 1rem;
        color: #595959;
    }
    .reportSubtitle {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;
        color: #595959;
        padding: 0.5rem 0 0.5rem 0.5rem;
    }
    // 健康报表中企业概况一栏的表格样式修改
    .reportSurveyTableStyle {
        .ant-table-thead {
            .ant-table-cell {
                background-color: #dafad4;
                font-size: 1rem;
            }
        }
        .ant-table-row {
            font-size: 1.25rem;
            font-weight: bold;
            color: #595959;
        }
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
            border: 1px solid #818181;
        }
        .ant-table table {
            border-collapse: collapse;
        }
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
        .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th {
            border-bottom: 0;
        }
    }
    // 健康报表中总结一栏的表格样式修改
    .reportSummaryTableStyle {
        .ant-table-thead {
            .ant-table-cell {
                background-color: #7cd480;
                font-size: 1rem;
            }
        }
        .ant-table-row {
            font-size: 0.93rem;
            font-weight: normal;
            color: #595959;
        }
        .ant-table-container table > thead > tr:first-child th:first-child,
        .ant-table-container table > thead > tr:first-child th:last-child {
            border-radius: 0;
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;