.ant-layout {
    height: 100%;
    .ant-layout-sider {
        background: #34404e;
    }
}

.logoIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 64px;
    height: 40px;
    background: #34404e;
    margin: 1rem 0;
    overflow: hidden;
    .Icon {
        width: 35px;
        height: 35px;
    }
}

.navTitle a {
    color: #fff;
}
.navTitle {
    font-size: 1rem;
}
.ant-menu-submenu {
    font-size: 1rem;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 45px;
    line-height: 45px;
}
#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}
// .clearfix:before {
//     content: '.';
//     height: 0;
//     display: block;
//     clear: both;
// }

@primary-color: #64D399;@border-radius-base: 5px;