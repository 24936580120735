.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 45rem;
}
.notFound .notFoundIcon {
    width: 35%;
    height: auto;
}
.notFound .tips {
    font-size: 1.3rem;
    font-weight: bold;
    color: #91ddff;
    word-wrap: 0.1rem;
}
@primary-color: #64D399;@border-radius-base: 5px;