.userInfo {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 10;
    height: 64px;
    padding: 0 1rem;
    background-color: #eeeeee;
    font-size: 1.4rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.userInfo .label {
    padding: 0 0.8rem;
}
.icon {
    margin-right: 0.5rem;
}
.header_title {
    height: 64px;
    font-size: 1.8rem;
    line-height: 60px;
    h1 {
        display: inline-block;
        position: relative;
        font-size: 1.4rem;
        color: #5d5d5d;
        font-weight: 500;
    }
}
.header_info {
    .userName {
        font-size: 1.25rem;
        color: #595959;
    }
}

@primary-color: #64D399;@border-radius-base: 5px;