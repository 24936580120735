.formBody {
    width: 100%;
    background: #fff;
    padding: 1rem;
    .gutter-row {
        margin-bottom: 15px;
    }
    .plus,
    .minus,
    .question {
        display: inline-block;
        font-size: 20px;
        cursor: pointer;
    }
    .plus {
        color: green;
    }
    .minus {
        color: red;
    }
    .btnOpera {
        float: right;
    }
    .formTable {
        margin-top: 15px;
        th,
        td {
            text-align: center !important;
        }
        .opera {
            span {
                cursor: pointer;
            }
        }
    }
}

.feedbackForm {
    .ant-row {
        .ant-form-item-control-input-content {
            .ant-image {
                margin-left: 1rem;
            }
        }
    }
}

.adminAccountStyle{
    .ant-form-item{
        margin-bottom: 10px !important;
    }
    .ant-upload.ant-upload-select-picture-card {
        width: 315px !important;
        height: 70px !important;
    }
    .ant-upload-list-picture-card-container{
        width: 315px !important;
        height: 70px !important;
        background: rgba(0,0,0,0.3);
    }
}

.bannerStyle{
    .ant-upload.ant-upload-select-picture-card {
        width: 350px !important;
        height: 70px !important;
    }
    .ant-upload-list-picture-card-container{
        width: 350px !important;
        height: 70px !important;
        // background: rgba(0,0,0,0.3);
    }
}

.ant-radio-button-wrapper:not(:first-child)::before{
    background: transparent;
}

@primary-color: #64D399;@border-radius-base: 5px;