.container{
  :global{
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
      text-shadow: 0 0 0.25px currentColor;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
      background: #64D399;
      padding: 6px 20px;
      font-weight: bolder;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
      border-bottom-color: #64D399;
    }
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
      margin: 0,
    }
    .ant-tabs-tab-btn{
      color: #666666,
    }
  }
}
@primary-color: #64D399;@border-radius-base: 5px;