.hiddenRecord{
  p{
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    // background: #D7D7D7;
    width: 6rem;
    text-align: center;
    height: 1.8rem;
    line-height: 1.8rem;
    // border: 1px solid #666;
    background-image: url('../assets/images/border.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@primary-color: #64D399;@border-radius-base: 5px;