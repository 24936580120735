.ItemViewStyle {
    width: 100%;
    .colBox {
        // background-color: aliceblue;
        background: rgba(255, 255, 255, 0.9);
        padding: 3%;
        border-radius: 10px;
        // box-shadow: 0px 0px 4px 2px #ccc;
        // box-shadow: 0px 2px 3px 1px #d4d8d8;
        // background: linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%);
        box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 20%);
    }
    .gutter-row {
        background: url(../../../assets/images/zheJTP.png) no-repeat;
        .col {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .value {
                font-size: 26px;
                color: #272727;
                color: #fff;
                font-weight: 600;
                margin-top: 1rem;
            }
            .title {
                font-size: 1rem;
                color: #7f7f7f;
                color: #fff;
            }
            .company {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.cardBox {
    .title {
        color: #000;
        font-weight: 600;
        font-size: 17px;
    }
    .cardItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .cardTitle {
            margin-top: 0.2rem;
        }
    }
}
.cardItemName {
    color: #595959;
    font-size: 13px;
}
.cardItemValue {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}
.ant-card-head {
    margin: 0 1.3rem !important;
    padding: 0 !important;
}
.ant-card-body {
    padding: 0.25rem 1.3rem !important;
}
.ant-card-head-title {
    padding: 5px 0 !important;
}
.CurrentStyle{
    .ant-empty .ant-empty-image {
        height: 130px;
    }
    .ant-empty-normal .ant-empty-image {
        height: 40px;
    }
    .ant-empty-description{
        color:rgba(0, 0, 0, 0.25);
    }
}

@primary-color: #64D399;@border-radius-base: 5px;