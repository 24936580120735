.containerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 3px #00f;
    background-size: 100% 100%;
    height: 100vh;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #fff;
        overflow: hidden;
        margin-top: 0.5rem;
    }
    .ant-empty-normal{
        color: #fff !important;
    }
}
.photo-slider {
    width: 95%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .dv-decoration-10 {
        position: absolute;
        width: auto;
        left: 6.5%;
        height: 5px;
        bottom: 0.5%;
    }
    .totalStyle {
        color: #fff;
        left: 2.5%;
        width: 97.5%;
        margin-top: 0.5rem;
        margin-left: 2.5%;
        position: absolute;
        bottom: 1%;
    }
}
.photo-details {
    width: 86%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .detailsContent {
        height: 86vh;
        display: 'flex';
        flex-direction: column;
        position: 'relative';
    }
}

.totalStyle {
    color: #333333;
    margin-top: 0.5rem;
    position: absolute;
}
.showStyle{
    color: #333333;
    left: 0%;
    margin-top: 0.5rem;
    margin-left: 2.5%;
    position: absolute;
    bottom: 2%;
}
@primary-color: #64D399;@border-radius-base: 5px;