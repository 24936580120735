html {
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f2f2f2; */
  /* line-height: 24px; */
  /* font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif; */
  background-color: #F0F2F5 !important;
  font-family: '微软雅黑';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-space-item {
  color: #000;
  font-size: 0.4rem;
}