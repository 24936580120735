.paginations {
    position: absolute;
    height: 3%;
    // z-index: 999999999;
    z-index: 999;
    bottom: 3%;
    color: #fff;
    left: 25.5%;
    width: 67.5%;
    .ant-pagination {
        color: #333;
        height: 100%;
    }
    .ant-pagination-prev {
        position: absolute;
        left: -3%;
        top: 48.5%;
        top: -963%;
        // display: none;
    }
    .ant-pagination-next {
        position: absolute;
        right: 1%;
        top: -967%;
        // display: none;
    }
    .ant-pagination-simple-pager {
        position: absolute;
        bottom: 0;
        left: 45%;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background: transparent;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background: transparent;
        border: 1px solid #ccc;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
        color: #fff;
        font-size: 30px;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        margin-right: 0px;
        padding: 0;
    }
}

.details {
    .ant-pagination-prev {
        top: -1700%;
        svg {
            font-size: 24px;
        }
    }
    .ant-pagination-next {
        top: -1700%;
        svg {
            font-size: 24px;
        }
    }
}
.camera {
    .ant-pagination-prev {
        top: -1700%;
        left: -25.5%;
        svg {
            font-size: 24px;
        }
    }
    .ant-pagination-next {
        top: -1700%;
        right: -3.5%;
        svg {
            font-size: 24px;
        }
    }
}
//分页按钮
.paginationsBtn {
    position: absolute;
    width: 100%;
    height: 100%;
    .paginationsPerv {
        cursor: pointer;
        position: absolute;
        left: -0.3rem;
        top: 46%;
        z-index: 999999;
    }
    .paginationsNext {
        position: absolute;
        cursor: pointer;
        right: 0.7rem;
        top: 46%;
        z-index: 999999;
    }
}

.sliderCardpaginations {
    position: absolute;
    height: 1%;
    z-index: 999999999;
    color: #fff;
    left: 0%;
    top: 0%;
    width: 100%;
    .ant-pagination {
        color: #fff;
        height: 100%;
    }
    .ant-pagination-prev {
        position: absolute;
        left: 0.5%;
        top: 4350%;
        .anticon {
            font-size: 1.5rem;
        }
    }
    .ant-pagination-next {
        position: absolute;
        right: 0.5%;
        top: 4350%;
        .anticon {
            font-size: 1.5rem;
        }
    }
    .ant-pagination-simple-pager {
        position: absolute;
        bottom: 0;
        left: 45%;
        display: none;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        background: transparent;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        background: transparent;
        border: 1px solid #ccc;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
        color: #fff;
        font-size: 30px;
    }
    .ant-pagination-simple .ant-pagination-simple-pager input {
        margin-right: 0px;
        padding: 0;
    }
}

@primary-color: #64D399;@border-radius-base: 5px;