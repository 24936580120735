.containerForm{
  :global{
    .ant-form-item{
       margin-bottom: 0,
    }
    .ant-form-item-control-input{
       margin: 2px 0,
    }
    .ant-radio-button-wrapper:first-child, .ant-radio-button-wrapper:last-child {
      border: 1px solid #71929B;
      color: #71929B;
    }
    .ant-radio-button-wrapper:last-child{
      border: 1px solid #E5756E;
      color: #E5756E;
    }
    .ant-radio-button-wrapper{
      margin: 0 0.5rem;
      border-radius: 5px;
      border: 1px solid #439BE3;
      color: #439BE3;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
      background-color: transparent,
    }
  }
}
@primary-color: #64D399;@border-radius-base: 5px;