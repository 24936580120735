.container {
  :global {
    .ant-table-pagination.ant-pagination {
      margin-top: 2rem;
    }
    .ant-timeline-item-head-custom {
      position: absolute;
      top: 14.5px;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-label{
      padding-right: 14px;
    }
    .ant-timeline-item-tail{
      border-left: 2px dotted #CCCCCC;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
      left: 20%;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
      left: calc(20% - 4px);
      width: calc(80% - 14px);
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
      position: absolute;
      top: -7.001px;
      width: calc(20% - 12px);
    }
  }
}
@primary-color: #64D399;@border-radius-base: 5px;