@color: #64D399;
// 右侧大容器
.container {
    width: 100%;
    height: 100%;
    overflow: visible;
    box-sizing: border-box;
    // overflow-y: scroll;
}
// 面包屑
.rumbCustom {
    border-top: 1px solid #eee;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    position: relative;
    padding: 14px 15px 15px !important;
    color: #999;
    box-shadow: 0px 2px 3px #e7ebeb;
    background: linear-gradient(180deg, #f5f6f6 0%, #f5f6f6 100%);
}
// 内容容器
.container .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    // margin: 1rem;
    // padding: 0 0.6rem;
    width: 98%;
    height: 90%;
    margin: 1rem 0;
    padding: 0 1rem;
}
.container .chart {
    width: 50%;
    float: left;
}
// .clearfix:after {
//     content: '.';
//     height: 0;
//     display: block;
//     clear: both;
// }

// form 取消 确定按钮的位置
.personal-btn {
    margin-left: 25%;
    margin-top: 5%;
    margin-bottom: 10%;
}
.tableBox {
    width: 100%;
}

.common_title {
    display: inline-block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    color: #333333;
    font-weight: 500;
    font-size: 1.25rem;
    padding-left: 1rem;
    vertical-align: middle;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 2px 3px #e7ebeb;
    background: linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%);
    .more{
        float: right;
        padding-right: 1rem;
        font-size: 1rem;
        cursor: pointer;
        color: #666666;
    }
    .moreText{
        padding-right: 1rem;
        font-size: 1rem;
        cursor: pointer;
        color: #666666;
    }
}

.ant-tabs-content {
    height: 100%;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
    font-size: 20px !important;
    color: #fff;
}

.ant-radio-button-wrapper {
    // padding: 0 5px !important;
    font-size: 12px !important;
}

// 内容容器样式
.bgColorFFF {
    background-color: #fff;
    padding: 1rem;
    border-right: 12px solid #f2f2f2;
}

// 表格的操作按钮样式
.opera {
    a,
    span {
        margin: 0 0.3rem;
        cursor: pointer;
        display: inline-block;
        height: 25px;
        line-height: 25px;
        color: #fff;
        white-space: nowrap;
        text-align: center;
        font-size: 12px;
        border: none;
        border-radius: 2px;
    }
    .edit {
        background-color: #4CBF81;
        padding: 0 10px;
        color: #fff;
    }
    .assign {
        background-color: #88AFC0;
        padding: 0 10px;
        color: #fff;
    }
    .del {
        background-color: #E5756E;
        padding: 0 5px;
        color: #fff;
    }
    .off{
        background-color:#6188A9;
        padding: 0 5px;
        color: #fff;
    }
    .reset {
        background-color: #ccc;
        padding: 0 5px;
    }
    .read {
        background-color: #439BE3;
        padding: 0 10px;
        color: #fff;
    }
    .replace{
        background-color:#E6965C;
        padding: 0 10px;
        color: #fff;
    }
}

// antd Divider
.ant-divider-horizontal {
    margin: 10px 0;
}

//提示框
.commonAlert {
    .ant-modal {
        width: 16rem !important;
        .ant-modal-close-x {
            width: 40px !important;
            height: 40px !important;
            line-height: 40px !important;
        }
        .ant-modal-header {
            padding: 8px 24px !important;
            height: 42px;
            line-height: 42px;
            border-bottom: 1px solid #eee !important;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            background-color: #f8f8f8 !important;
            border-radius: 2px 2px 0 0;
        }
        .ant-modal-body {
            text-align: center !important;
        }
        .ant-modal-footer {
            border-top: none !important;
            div {
                justify-content: flex-end !important;
            }
        }
    }
}

.GlobalModal {
    .ant-modal-footer {
        text-align: center !important;
    }
}

.ant-divider-horizontal {
    margin: 8px 0 !important;
}

//定时开关和一键开关右侧设备复选框
.ant-checkbox-group-item {
    width: 10rem;
}

.ant-switch{
    background-color: #71929B !important;
}
.ant-switch-checked{
    background-color: #439BE3 !important;
}

.roleStyle {
    .ant-tabs-nav .ant-tabs-tab-active{
        background: @color;
        padding: 0px 20px;
    }
    .ant-tabs-tab{
        padding: 10px 0;
    }
}

@primary-color: #64D399;@border-radius-base: 5px;