.container {
    height: 100%;
    width: 100%;
    :global {
        .ant-spin-nested-loading {
            width: 100%;
            height: 100%;
            .ant-spin{
                min-height: 100%;
            }
            .ant-spin-container {
                width: 100%;
                height: 100%;
            }
        }
    }
    .content {
        height: 100%;
        width: 100%;
        :global {
            .ant-spin-blur {
                opacity: 0;
                ::after {
                    opacity: 0;
                }
            }
            //.ant-spin-container {
            //    :after {
            //        background: transparent;
            //    }
            //}
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;