.timeSearch {
    background-color: transparent;
    :global {
        .ant-picker {
            border: none;
            .ant-picker-input {
                display: none;
            }
            .ant-picker-range-separator {
                display: none;
            }
        }
    }
}

.containers{
    :global {
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
            background: #64D399;
            padding: 8px 33px;
          }
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
            border-bottom-color: #64D399;
            padding: 8px 33px;
          }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            color: #ffffff;
        }
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
            border-radius: 10px;
        }
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab{
            padding: 8px 33px;
        }
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
            padding: 8px 33px;
        }
        .ant-radio-button-wrapper{
            padding: 0 9px;
        }
        .ant-tree-title{
            vertical-align: middle;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 95px;
            display: inline-block;
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;