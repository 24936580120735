.container {
  :global {
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-right: 0;
      }
      .ant-select-item-option{
        text-align: center !important;
      }
      .rc-virtual-list-holder-inner{
        text-align: center !important;
      }
  }
}

@primary-color: #64D399;@border-radius-base: 5px;