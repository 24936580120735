.bigScreenContainer {
    .title {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: 50px;
        color: #09AED9;
        font-size: 20px;
        margin-left: 35px;
        height: 2vh;
        left: 0;
        top: 0;
        .titleName {
            height: 100%;
            display: flex;
            font-size: 20px;
            align-items: center;
            img {
                width: 17px;
                margin-right: 6px;
            }
        }
    }
    width: 100vw;
    height: 100vh;
    color: #2ca7bb;
    padding: 12px;
    position: relative;
    background-image: url('../../../assets/images/Insurance-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    background-attachment: fixed;
    overflow: hidden;
    .bigScreenHeader {
        display: flex;
        align-content: center;
        justify-content: space-between;
        // height: 8vh;
        overflow: auto;
        .left {
            flex: 1;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            .ant-select{
                background-color: transparent;
                color: #2CA7BB;
                border: none;
            }
        }
        .center {
            width: 40vw;
            // height: 8vh;
            padding-top: 1vh;
            display: flex;
            justify-content: center;
            font-size: 30px;
            font-weight: 400;
            img {
                width: 38px;
                height: 39px;
                margin-right: 20px;
                margin-top: 4px;
            }
        }
        .right {
            flex: 1;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
    .bigScreenContent {
        padding: 0 12px;
        overflow: hidden;
        // .contentFirst {
        //     display: flex;
        //     align-items: center;
        //     height: 20vh;
        //     margin-top: 0.6rem;
        .left {
            width: 30%;
            height: 100%;
            position: relative;
            .content {
                padding: 0 5%;
                padding-top: 8%;
                .leftCenter {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .leftCenterItem {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        .img {
                            font-size: 52px;
                        }
                        .text {
                            // margin-left: 10px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            width: 8rem;
                            height: 8rem;
                            background-image: url('../../../assets/images/jinrigaojing_bg.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            background-position: center top;
                            justify-content: center;
                            div {
                                font-size: 20px;
                                margin-top: -0.5rem;
                                text-align: center;
                                color:#FFFFFF;
                                span{
                                    font-size: 12px;
                                    color:#FFFFFF;
                                }
                            }
                        }
                        .titleName{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            p {
                                font-size: 14px;
                                text-align: center;
                                color:#FFFFFF;
                            }
                        }
                    }
                }
                .leftBottom {
                    height: 3.5vh;
                    width: 100%;
                    border: 1px solid #c6f6ff;
                    border-radius: 12px;
                    display: flex;
                    color: #ffffff;
                    overflow: hidden;
                    margin-top: 0.5vh;
                    .leftBottomLeft {
                        display: flex;
                        align-items: center;
                        width: 50%;
                        padding: 0 5%;
                        font-size: 20px;
                        background-color: #4c689b;
                        div {
                            margin-left: 20px;
                        }
                    }
                    .leftBottomRight {
                        width: 50%;
                        font-size: 20px;
                        display: flex;
                        color: #ffffff;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .bottom{
               .content{
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                padding: 3.5rem 2rem 0;
                .leftCenterItem {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .img {
                        font-size: 52px;
                    }
                    .text {
                        // margin-left: 10px;
                        color: #DBE5F8;
                        display: flex;
                        align-items: center;
                        width: 7rem;
                        height: 7rem;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-position: center top;
                        justify-content: center;
                        flex-direction: 'row';
                        div {
                            font-size: 20px;
                            margin-top: -0.5rem;
                            text-align: center;
                            color:#DBE5F8;
                            span{
                                font-size: 12px;
                                color:#DBE5F8;
                            }
                        }
                    }
                    .titleName{
                        p {
                            font-size: 14px;
                            text-align: center;
                            color:#DBE5F8;
                            margin-top: 8px;
                        }
                        div{
                            font-size: 22px;
                            text-align: center;
                            color:#DBE5F8;
                            margin-top: 8px;
                            span{
                                font-size: 16px;
                                color:#DBE5F8;
                            }
                        }
                    }
                }
               } 
            }
        }
        .center {
            width: 40%;
            height: 100%;
            position: relative;
            .content {
                height: 100%;
                display: flex;
                .everyOne {
                    width: 50%;
                    height: 100%;
                }
            }
        }
        .right {
            width: 30%;
            height: 100%;
            position: relative;
            // .content {
            //     height: 100%;
            //     width: 100%;
            //     position: relative;
            // }
        }
        // }
        .contentCenter {
            height: 54vh;
            display: flex;
            padding-top: 0.5rem;
            .left {
                width: 30%;
                height: 100%;
                position: relative;
                .top {
                    position: relative;
                    width: 100%;
                    height: 40%;
                    .center {
                        .leftCenter {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .leftCenterItem {
                                display: flex;
                                align-items: center;
                                .img {
                                    font-size: 52px;
                                }
                                .text {
                                    margin-left: 10px;
                                    color: #ffffff;
                                    p {
                                        font-size: 14px;
                                    }
                                    div {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottom {
                    position: relative;
                    width: 100%;
                    height: 60%;
                }
            }
            .center {
                width: 40%;
                height: 100%;
                padding: 1rem;
                position: relative;
                background-image: url('../../../assets/images/insurance_border.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center top;
                /*设置告警点*/
                .alert_point {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    .circle {
                        // background-color: #ac002f;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        top: 8px;
                        left: 8px;
                        position: absolute;
                    }
                    .circle_bottom {
                        // background-color: #ac002f;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        filter: alpha(opacity=40);
                        top: 4px;
                        left: 4px;
                        position: absolute;
                        -webkit-animation: twinkling 2.1s infinite ease-in-out;
                        animation: twinkling 2.1s infinite ease-in-out;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                    }
                    .circle_bottom2 {
                        // background-color: #ac002f;
                        width: 32px;
                        height: 32px;
                        top: 0px;
                        left: 0px;
                        border-radius: 50%;
                        filter: alpha(opacity=20);
                        -webkit-animation: twinkling 2.1s infinite ease-in-out;
                        animation: twinkling 2.1s infinite ease-in-out;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                    }
                    @-webkit-keyframes twinkling {
                        0% {
                            opacity: 0.2;
                            filter: alpha(opacity=20);
                            -webkit-transform: scale(1);
                        }
                        50% {
                            opacity: 0.5;
                            filter: alpha(opacity=50);
                            -webkit-transform: scale(1.12);
                        }
                        100% {
                            opacity: 0.2;
                            filter: alpha(opacity=20);
                            -webkit-transform: scale(1);
                        }
                    }
                    @keyframes twinkling {
                        0% {
                            opacity: 0.2;
                            filter: alpha(opacity=20);
                            -webkit-transform: scale(1);
                        }
                        50% {
                            opacity: 0.5;
                            filter: alpha(opacity=50);
                            -webkit-transform: scale(1.12);
                        }
                        100% {
                            opacity: 0.2;
                            filter: alpha(opacity=20);
                            -webkit-transform: scale(1);
                        }
                    }
                }
            }
            .right {
                width: 30%;
                height: 100%;
                .top {
                    position: relative;
                    width: 100%;
                    height: 46%;
                }
                .bottom {
                    position: relative;
                    width: 100%;
                    height: 50%;
                }
            }
        }
        .contentBottom {
            height: 35vh;
            display: flex;
            align-items: center;
            padding-top: 0.8rem;
            // .left {
            //     width: 17vw;
            //     height: 100%;
            //     position: relative;
            // }
            // .leftLine {
            //     // width: 21vw;
            //     width: 38vw;
            //     height: 100%;
            //     position: relative;
            //     .swiper {
            //         overflow: hidden;
            //         height: 80%;
            //         margin-top: 5%;
            //         width: 100%;
            //         .rankContainer {
            //             height: 3vh;
            //             display: flex !important;
            //             align-items: center;
            //             .rankItem {
            //                 width: 100%;
            //                 display: flex;
            //                 align-items: center;
            //                 justify-content: flex-start;
            //                 .text {
            //                     color: #ffffff;
            //                     width: 25%;
            //                     overflow: hidden;
            //                     text-overflow: ellipsis;
            //                     white-space: nowrap;
            //                     font-size: 15px;
            //                 }
            //                 .line {
            //                     width: 65%;
            //                     height: 12px;
            //                     margin-left: 2%;
            //                     background-color: #0a487e;
            //                     border-radius: 8px;
            //                     overflow: hidden;
            //                     .lineGradient {
            //                         height: 12px;
            //                         background-image: linear-gradient(to right, #3a74e0, #4aa7cf);
            //                         border-radius: 8px;
            //                         position: relative;
            //                         .value {
            //                             position: absolute;
            //                             right: 5px;
            //                             top: -3px;
            //                             color: #ffffff;
            //                             font-size: 10px;
            //                         }
            //                     }
            //                 }
            //             }
            //         }
            //         :global {
            //             .slick-list {
            //                 overflow: unset;
            //             }
            //         }
            //     }
            // }
            .center {
                width: 49vw;
                height: 100%;
                position: relative;
            }
            .right {
                height: 98%;
                overflow: hidden;
                position: relative;
                width: 48vw;
                padding-right: 50px;
                .swiper {
                    overflow: hidden;
                    height: 80%;
                    margin-top: 5%;
                    width: 100%;
                    .swiperItem {
                        height: 3.5vh;
                        width: 100%;
                        display: flex !important;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: #ffffff;
                        border-bottom: .1px solid rgba(0,147,167,0.3);
                        .message {
                            width: 68%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .statusAlarm {
                            text-align: right;
                            width: 30%;
                            display: flex;
                            justify-content: space-between;
                            margin-left: 2%;
                            .alarm {
                                margin-right: 20px;
                                padding: 0 0.8rem;
                                border-radius: 10px;
                            }
                        }
                    }
                    :global {
                        .slick-list {
                            overflow: unset;
                        }
                    }
                }
            }
        }
    }
    :global {
        .ant-empty-normal{
            color: #09637B;
        }
        .ant-empty-img-simple-path {
            fill: #09637B;
        }
        .ant-empty-img-simple-g {
            stroke: #09637B;
        }
        .ant-empty-img-simple-ellipse {
            fill: #09637B;
        }
        .ant-empty-description div {
            color: #09637B !important;
        }
        .ant-carousel {
            // margin-top: -1rem;
            .slick-dots-top {
                top: -19px;
                z-index: 999999;
            }
            .slick-dots {
                li {
                    width: 6rem;
                    height: 30px;
                    font-size: 0.8rem;
                    &::after {
                        content: '1111';
                    }
                    button {
                        width: 6rem;
                        height: 30px;
                        opacity: 1;
                        font-size: 0.8rem;
                        background: #163548;
                        color: #acb6c3;
                        border-radius: 5px;
                    }
                }
                li.slick-active {
                    button {
                        background: #1d5a82;
                        color: #fff;
                    }
                }
            }
        }
        .ant-select{
            color: #2CA7BB;
            font-size: 18px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            background-color: transparent;
            border:none;
            // padding: 0px;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #09637B;
        }
        .ant-select-arrow{
            position: absolute;
            left: -27px;
            width: 20px;
            height: 25px;
            top: 10px;
            z-index: 9999;
            display: none;
        }
        .ant-pagination-prev{
            position: absolute;
            left: -2.5%;
            top: -1700%;
            svg{
                color: #fff
            }
        }
        .ant-pagination-next {
            position: absolute;
            right: -2.5%;
            top: -1700%;
            svg{
                color: #fff
            }
        }
        .ant-pagination{
            color: #fff
        }
        .ant-pagination-simple-pager{
            // display: none;
        }
        .ant-empty-image {
            height: 50px !important;
        }
        .ant-empty-image img {
            height: 50px !important;
            width: 50px !important;
        }
    }
}

.insuranceSelectStyle{
    :global{
        .ant-select-item-group{
            color: rgba(255, 255, 255, 0.95);
        }
        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background-color: #02A2CC;
        }
        .ant-select-item{
            color: rgba(255, 255, 255, 0.85);
        }
        .ant-select-single.ant-select-open .ant-select-selection-item {
            color: '#2CA7BB',
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: #09637B;
        }
        /*修改滚动条样式*/
        .rc-virtual-list-holder::-webkit-scrollbar{
            width:0.3rem;
        }
        .rc-virtual-list-holder::-webkit-scrollbar-track{
            background:rgba(62,75,77,0.5);
            border-radius:2px;
        }
        .rc-virtual-list-holder::-webkit-scrollbar-thumb{
            background:rgba(18,219,255,1);
            border-radius:10px;
            width: 0.02rem;
        }
        .ant-select-selection-item:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }
}


@primary-color: #64D399;@border-radius-base: 5px;