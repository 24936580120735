.ant-table table {
    text-align: center !important;
    .ant-table-thead {
        tr {
            th {
                text-align: center !important;
            }
        }
    }
}

.ant-table-pagination.ant-pagination {
    position: absolute;
    right: 0;
    width: 80%;
    float: right;
}

.styleTableReportData {
    .ant-table {
        .ant-table-content {
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td,
            .ant-table tfoot > tr > th,
            .ant-table tfoot > tr > td {
                padding: 13px 16px;
            }
        }
    }
}
.styleTableReportDataCount {
    margin-top: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 1rem;
    .ant-table {
        .ant-table-content {
            .ant-table-thead {
                display: none;
            }
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td,
            .ant-table tfoot > tr > th,
            .ant-table tfoot > tr > td {
                padding: 7px 16px;
            }
        }
    }
}

//表格中行被选中样式
.selected_row {
    background: #64d399 !important;
    .ant-table-cell {
        p {
            color: #666674;
        }
    }
}
.ant-table-row {
    cursor: pointer;
}

//能耗分析中峰谷用电的表格样式修改
.peakValleyTableStyle {
    .ant-table {
        line-height: 1.2715;
        .ant-table-container {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            .ant-table-content {
                table {
                    height: 29.2vh;
                }
            }
        }
        .ant-table-thead {
            box-shadow: rgb(231 235 235) 0px 2px 3px;
            .ant-table-cell {
                background: linear-gradient(rgb(241, 241, 254) 0%, rgb(245, 246, 250) 100%);
            }
        }
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 10px;
    }
}


.actionlogStyle{
    .ant-table-pagination.ant-pagination {
        position: relative;
    }
}
@primary-color: #64D399;@border-radius-base: 5px;