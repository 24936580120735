.powerSurvey {
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(220, 231, 235, 0.16);
    height: 200px;
    .powerSurvey_item {
        padding: 1rem 2rem;
        text-align: center;
        p {
            padding: 0;
            margin: 0;
        }
        .name {
            margin-bottom: 0.2rem;
            font-size: 0.93rem;
            color: #666666;
            line-height: 25px;
            display: block;
        }
        .number {
            font-size: 1.25rem;
            line-height: 28px;
            font-weight: 500;
            color: #595959;
        }
        .count {
            color: #f7ac4b;
            font-size: 1.2rem;
        }
        .company {
            font-size: 1rem;
            margin-left: 0.2rem;
            color: #666666;
            // margin-right: 1.3rem;
        }
    }
}

.surveyList {
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(220, 231, 235, 0.16);
    height: 200px;
    .surveyItem {
        padding: 1rem;
        text-align: center;
        p {
            padding: 0;
            margin: 0;
        }
        .name {
            margin: 0.7rem 0;
            font-size: 0.96rem;
            line-height: 22px;
            color: #666666;
        }
        .number {
            font-size: 1.25em;
            color: #595959;
            font-weight: 500;
        }
        .count {
            color: #faae2c;
            font-size: 1.25rem;
        }
        .company {
            font-size: 1rem;
            margin-left: 0.2rem;
            color: #666666;
        }
    }
}

.number {
    font-size: 1.2rem;
}

.slider-data {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #000;
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0 0.5rem;
    .slick-list {
        height: 248px !important;
        min-height: 200px !important;
    }
}
.ant-list-item {
    padding: 5px 0 !important;
    flex-direction: column;
    align-items: inherit !important;
}
.workbench_title {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 0.95rem;
}

.workbench_message {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0.42rem 0rem;
    p {
        font-size: 0.4rem;
        display: inline-block;
    }
    .time{
        text-align: center;
    }
}
// 工作台最新告警
.message{
    min-height: 26.4vh;
}
//企业概况最新告警
.generalViewMessage {
    min-height: 27.5vh;
    .slick-list {
        height: 258px !important;
        min-height: 258px !important;
    }
}
// 最新告警奇偶行背景颜色
.message .slick-slide:nth-child(even) {
    background-color: #f4f5f5;
}
.message .slick-slide:nth-child(odd) {
    background-color: #ffffff;
}

.animate {
    transition: margin-top 2s linear;
}

.rc-virtual-list {
    position: relative;
    height: 100%;
    width: 100%;
}
.rc-virtual-list-holder-inner {
    transform: translate(0px) !important;
}

//告警统计右侧样式
.grade {
    margin: 0.05rem 0 0rem;
    height: 20px;
    line-height: 18px;
    p {
        width: 90%;
        height: 38px;
        border-radius: 5px;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        margin: 0.5rem 0;
        background: rgba(244, 249, 251, 0.8);
    }
    .grade_name {
        float: left;
        height: 17px;
        font-size: 0.95rem;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
    }
    .grade_value_total {
        float: right;
        // height: 22px;
        // line-height: 22px;
        color: #eb4442;
        font-size: 1.4rem;
        font-weight: 500;
        // font-family: 'Agency FB';
        font-family: 'Regular';
        .grade_company {
            vertical-align: bottom;
        }
    }
    .grade_value {
        float: right;
        // height: 22px;
        font-size: 1.2rem;
        font-weight: 500;
        // line-height: 22px;
        color: #595959;
    }
    .grade_number {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
    .grade_name_dot0 {
        //异常
        &::before {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #f7cd85;
            border-radius: 5px;
            vertical-align: middle;
            margin: 0 0.5rem 0 0;
        }
    }
    .grade_name_dot1 {
        //高危
        &::before {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #ef9076;
            border-radius: 5px;
            vertical-align: middle;
            margin: 0 0.5rem 0 0;
        }
    }
    .grade_name_dot2 {
        //故障
        &::before {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #c7c7c7;
            border-radius: 5px;
            vertical-align: middle;
            margin: 0 0.5rem 0 0;
        }
    }
    .grade_company {
        font-size: 0.85rem;
        // height: 22px;
        // float: right;
        color: #666666;
        font-weight: 400;
        vertical-align: middle;
    }
}

@primary-color: #64D399;@border-radius-base: 5px;