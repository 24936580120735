.bigScreenContainer {
    .title {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: 50px;
        color: #55c1ff;
        font-size: 20px;
        margin-left: 35px;
        height: 2vh;
        left: 0;
        top: 0;
        .titleName {
            height: 100%;
            display: flex;
            font-size: 20px;
            align-items: center;
            img {
                width: 17px;
                margin-right: 6px;
            }
        }
    }
    width: 100vw;
    height: 100vh;
    color: #13adc4;
    padding: 12px;
    position: relative;
    background-image: url('../assets/images/bigscreenbg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    background-attachment: fixed;
    overflow: hidden;
    .bigScreenHeader {
        display: flex;
        align-content: center;
        justify-content: space-between;
        // height: 8vh;
        overflow: auto;
        .left {
            flex: 1;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
        .center {
            width: 40vw;
            // height: 8vh;
            padding-top: 1vh;
            display: flex;
            justify-content: center;
            font-size: 30px;
            font-weight: 400;
            img {
                width: 38px;
                height: 39px;
                margin-right: 20px;
                margin-top: 4px;
            }
        }
        .right {
            flex: 1;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
    .bigScreenContent {
        padding: 0 12px;
        overflow: hidden;
        .contentFirst {
            display: flex;
            align-items: center;
            height: 20vh;
            margin-top: 0.6rem;
            .left {
                width: 30%;
                height: 100%;
                position: relative;
                .content {
                    padding: 0 5%;
                    padding-top: 8%;
                    .leftCenter {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .leftCenterItem {
                            display: flex;
                            align-items: center;
                            .img {
                                font-size: 52px;
                            }
                            .text {
                                margin-left: 10px;
                                color: #ffffff;
                                p {
                                    font-size: 14px;
                                }
                                div {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                    .leftBottom {
                        height: 3.5vh;
                        width: 100%;
                        border: 1px solid #c6f6ff;
                        border-radius: 12px;
                        display: flex;
                        color: #ffffff;
                        overflow: hidden;
                        margin-top: 0.5vh;
                        .leftBottomLeft {
                            display: flex;
                            align-items: center;
                            width: 50%;
                            padding: 0 5%;
                            font-size: 20px;
                            background-color: #4c689b;
                            div {
                                margin-left: 20px;
                            }
                        }
                        .leftBottomRight {
                            width: 50%;
                            font-size: 20px;
                            display: flex;
                            color: #ffffff;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
            .center {
                width: 40%;
                height: 100%;
                position: relative;
                .content {
                    height: 100%;
                    display: flex;
                    .everyOne {
                        width: 50%;
                        height: 100%;
                    }
                }
            }
            .right {
                width: 30%;
                height: 100%;
                position: relative;
                // .content {
                //     height: 100%;
                //     width: 100%;
                //     position: relative;
                // }
            }
        }
        .contentCenter {
            height: 44.5vh;
            display: flex;
            padding-top: 1rem;
            .left {
                width: 30%;
                height: 100%;
                position: relative;
                .top {
                    position: relative;
                    width: 100%;
                    height: 46%;
                }
                .bottom {
                    position: relative;
                    width: 100%;
                    height: 50%;
                }
            }
            .center {
                width: 40%;
                height: 100%;
                position: relative;
            }
            .right {
                width: 30%;
                height: 100%;
                .top {
                    position: relative;
                    width: 100%;
                    height: 46%;
                }
                .bottom {
                    position: relative;
                    width: 100%;
                    height: 50%;
                }
            }
        }
        .contentBottom {
            height: 25vh;
            display: flex;
            align-items: center;
            padding-top: 0.8rem;
            // .left {
            //     width: 17vw;
            //     height: 100%;
            //     position: relative;
            // }
            .leftLine {
                // width: 21vw;
                width: 38vw;
                height: 100%;
                position: relative;
                .swiper {
                    overflow: hidden;
                    height: 80%;
                    margin-top: 5%;
                    width: 100%;
                    .rankContainer {
                        height: 3vh;
                        display: flex !important;
                        align-items: center;
                        .rankItem {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .text {
                                color: #ffffff;
                                width: 25%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 15px;
                            }
                            .line {
                                width: 65%;
                                height: 12px;
                                margin-left: 2%;
                                background-color: #0a487e;
                                border-radius: 8px;
                                overflow: hidden;
                                .lineGradient {
                                    height: 12px;
                                    background-image: linear-gradient(to right, #3a74e0, #4aa7cf);
                                    border-radius: 8px;
                                    position: relative;
                                    .value {
                                        position: absolute;
                                        right: 5px;
                                        top: -3px;
                                        color: #ffffff;
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                    :global {
                        .slick-list {
                            overflow: unset;
                        }
                    }
                }
            }
            .center {
                width: 37vw;
                height: 100%;
                position: relative;
            }
            .right {
                height: 98%;
                overflow: hidden;
                position: relative;
                width: 22vw;
                padding-right: 50px;
                .swiper {
                    overflow: hidden;
                    height: 80%;
                    margin-top: 8%;
                    width: 100%;
                    .swiperItem {
                        height: 3.5vh;
                        width: 100%;
                        display: flex !important;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: #ffffff;
                        .message {
                            width: 60%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .statusAlarm {
                            text-align: right;
                            width: 40%;
                            .alarm {
                                margin-right: 20px;
                            }
                        }
                    }
                    :global {
                        .slick-list {
                            overflow: unset;
                        }
                    }
                }
            }
        }
    }
    :global {
        .ant-empty-img-simple-path {
            fill: #08294c;
        }
        .ant-empty-img-simple-g {
            stroke: #08294c;
        }
        .ant-empty-img-simple-ellipse {
            fill: #08294c;
        }
        .ant-empty-description div {
            color: #08294c !important;
        }
        .ant-carousel {
            // margin-top: -1rem;
            .slick-dots-top {
                top: -19px;
                z-index: 999999;
            }
            .slick-dots {
                li{
                    width: 6rem;
                    height: 30px;
                    font-size: 0.8rem;
                    &::after{
                        content: '1111';
                    }
                    button{
                        width: 6rem;
                        height: 30px;
                        opacity: 1;
                        font-size: 0.8rem;
                        background: #163548;
                        color: #acb6c3;
                        border-radius: 5px;
                    }
                }
                li.slick-active{
                    button{
                        background: #1D5A82;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;