.loginWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/login_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
    .anticon svg {
        /*密码隐藏和显示图标*/
        color: #347da4;
    }
    .ant-input-affix-wrapper .ant-input-suffix {
        border-bottom: 1px solid #347da4;
        margin-left: 0 !important;
        margin: 0 !important;
    }
    .contentWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 60%;
        margin: 0 auto;
        .leftSide {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 30%;
            height: 100%;
            .loginBg {
                width: 100%;
                height: auto;
            }
        }
        .loginForm {
            width: 26rem;
            height: 28rem;
            border-radius: 0.2rem;
            border: 1px solid #57bed0;
            background: rgba(7, 54, 87, 0.4);
            .title {
                width: 100%;
                height: 4rem;
                padding: 0.5rem 0;
                text-align: center;
                margin-top: 1.5rem;
                .logo {
                    height: 100%;
                    width: 10rem;
                }
                .name {
                    height: 50%;
                    margin-left: 0.8rem;
                }
            }
            .dataWrap {
                width: 100%;
                .ant-btn-primary {
                    width: 100%;
                }
                .ant-form-item {
                    margin-bottom: 25px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
                .formForgot {
                    margin-bottom: 10px;
                }
            }
            .ant-input-affix-wrapper .ant-input {
                min-height: 100%;
                background: transparent;
                border: none;
                border-bottom: 1px solid #347da4;
                border-radius: 0 !important;
                /* border: 1px solid #347DA4;*/
                outline: none;
                color: #fff;
            }
            .login-form-forgot {
                float: right !important;
                color: #1bc7d3;
                height: 26px;
                line-height: 26px;
                cursor: pointer;
            }
            .ant-form {
                margin-top: 2.5rem;
            }
            .ant-input-affix-wrapper {
                background-color: transparent;
                border: transparent;
                outline: none;
                line-height: 2.1;
                padding: 0;
                /*border: 1px solid #347DA4;*/
            }
            .ant-input-affix-wrapper .ant-input:not(:first-child) {
                padding-left: 5px;
            }
        }
    }
    //忘记密码
    .forgot .formForgot .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .forget-title {
        display: flex;
        flex-direction: row;
        align-items: top;
        font-size: 2rem;
        color: #fff;
        margin: 0 0 2rem;
    }
    .cs-forget {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        height: 65%;
        margin: 0 auto;
        background: url(../../assets/images/login-bg-k.png) no-repeat;
        background-size: 100% 100%;
        .ant-form-inline .ant-form-item-with-help {
            margin-bottom: 0px !important;
        }
        .ant-form-item-control-input {
            margin-bottom: 20px !important;
        }
        .ant-input-affix-wrapper {
            border: none;
        }
        .formBtn {
            width: 100%;
            margin-top: 2rem;
        }
        .forget-title-sub {
            font-size: 2rem;
            font-family: PingFangSC-Semibold, PingFangSC;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
            line-height: 0.56rem;
            text-shadow: 0px 2px 11px rgba(255, 255, 255, 0.5), 0px 0px 20px rgba(18, 219, 255, 1);
            margin: 3.5rem 0 4.5rem;
        }
        button {
            width: 20.4rem;
            font-size: 1rem;
            color: #000;
            border: none;
            margin: 0 1rem 2rem;
            background: #fff;
        }
        button.active,
        button:active,
        button:focus,
        button:hover {
            width: 20.4rem;
            font-size: 1rem;
            border: none;
            color: #fff;
            background-color: #3e5172;
        }
        button:hover {
            box-shadow: 0 0 0.2rem 0 #12dbff;
        }
        button[disabled] {
            border: none;
            color: #fff;
            font-weight: 600;
            background: #347da4;
        }
        input {
            height: 2rem;
            line-height: 2rem;
            background: none !important;
            /*border: 1px solid #347DA4;*/
            border-bottom: 1px solid #347da4 !important;
            border-radius: 0 !important;
            font-size: 0.9rem;
            font-family: PingFangSC-Regular, PingFangSC;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
        }
        input:focus {
            box-shadow: none;
        }
        input::-webkit-input-placeholder {
            color: #777;
        }
        .errInfo {
            width: 4rem;
            height: 0.4rem;
            font-size: 0.58rem;
            font-family: PingFangSC-Regular, PingFangSC;
            font-weight: 400;
            color: rgba(255, 93, 97, 1);
            line-height: 0.4rem;
        }
        .forget-item {
            height: 3rem;
            width: 20rem;
            border-bottom: 0.15rem solid #12dbff;
            margin: 0 0 1rem;
            span {
                font-size: 1.15rem;
                font-family: PingFangSC-Regular, PingFangSC;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 3rem;
                position: relative;
            }
        }
        .phone {
        }
        .msgCode {
            margin-bottom: 5rem;
            .valide-code {
                width: 1rem;
                height: 1rem;
                font-size: 0.86rem;
                font-family: PingFangSC-Medium, PingFangSC;
                font-weight: 500;
                color: rgba(18, 219, 255, 1);
                line-height: 0.44rem;
                cursor: pointer;
            }
            .valide-disabled {
                cursor: no-drop;
                text-align: right;
            }
            input {
                width: 11.6rem;
            }
        }
        .password {
            input {
                width: 4rem;
            }
        }
        .password1 {
            input {
                width: 4rem;
            }
        }
        .info1 {
            width: 7.04rem;
            height: 0.44rem;
            font-size: 0.92rem;
            font-family: PingFangSC-Regular, PingFangSC;
            font-weight: 400;
            color: rgba(186, 210, 229, 1);
            line-height: 0.44rem;
        }
        .info2 {
            width: 1.44rem;
            height: 1.44rem;
        }
        .info3 {
            width: 3.72rem;
            height: 0.4rem;
            font-size: 0.32rem;
            font-family: PingFangSC-Regular, PingFangSC;
            font-weight: 400;
            color: rgba(186, 210, 229, 1);
            line-height: 0.4rem;
        }
    }
}
// 图标
.site-form-item-icon {
    font-size: 1.25rem;
    color: #000;
    margin-right: 0.5rem;
}
.ant-input-affix-wrapper {
    background-color: transparent !important;
}

.logins .ant-form-item-control-input-content {
    /* border: 1px solid #10a7b9;*/
}
.logins .formForgot .ant-form-item-control-input-content {
    border: none;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #1daefa !important;
}
.ant-input-password-icon {
    color: #fff !important;
}

.ant-input-affix-wrapper .ant-input-suffix,
.ant-input-affix-wrapper .ant-input-prefix {
    margin: 0 4px !important;
    color: #fff;
}

#components-form-demo-normal-login .login-form {
    width: 100%;
    margin-top: 3rem;
}

#components-form-demo-normal-login .login-form {
    max-width: 500px;
    min-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
    margin-top: 1rem;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none !important;
}

/* 获取验证码 */
.checkBtn {
    display: inline-block;
    width: 88px;
    font-size: 12px;
    line-height: 13px !important;
    height: 15px;
    text-align: center;
    cursor: pointer;
}

//重新设置忘记密码背景图
.forgetWrap {
    background: url('../../assets/images/login_bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.forgetBtn{
    background: #1bc7d3 !important;
    color: #fff !important;
}

@primary-color: #64D399;@border-radius-base: 5px;