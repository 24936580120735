.container {
    background-color: #ffffff;
    overflow: auto;
    height: 90%;
    :global {
        .ant-input-wrapper {
            background-color: #ffffff;
        }
        .ant-tree-treenode {
            width: 100%;
            .ant-tree-node-content-wrapper {
                width: 100%;
            }
        }
        .ant-tree{
            max-height: 73vh;
        }
    }
    .title {
        .titleIcon {
            display: none;
        }
    }
    .title:hover .titleIcon {
        display: block;
    }
}

@primary-color: #64D399;@border-radius-base: 5px;