.modal_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.1rem;
    position: relative;
    // z-index: 99999;
}
.img_wap {
    padding-bottom: 56.5%;
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
}

.img_wap img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
// 占位图
.img_placeholder {
    // background: #3a6f79;
    // padding-bottom: 56%;
    width: 100%;
    position: relative;
}
// 图片上的蒙版效果
.mengban {
    position: absolute;
    left: 0;
    right: 0;
    line-height: 30px;
    display: flex;
    margin: 0 4px;
    padding: 0 0.2rem;
    overflow: hidden;
    .mengban_location {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff;
        .icon {
            font-size: 12px;
            vertical-align: middle;
        }
        .location {
            font-size: 12px;
            vertical-align: middle;
            color: #fff;
        }
    }
    .mengban_name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
        font-size: 12px;
        color: #fff;
    }
    .mengban_time {
        font-family: '楷体';
        color: #fff;
        font-size: 12px;
    }
    .ant-image-mask-info{
        display: none;
    }
}
.mengbanTop {
    top: 0;
    background: rgba(0, 0, 0, 0.2);//rgba(85, 117, 93, 0.5) rgba(240,255,245, 0.5)
}
.mengbanBottom {
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);//rgba(100, 211, 153, 0.18) rgba(82, 104, 87, 0.5)
}

@primary-color: #64D399;@border-radius-base: 5px;