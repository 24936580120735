.container {
    height: 100%;
    width: 100%;
    :global {
        .ant-layout-sider {
            background: #34404e;
        }
        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
            color: rgba(255, 255, 255, 0.65);
            background: #34404e;
        }
        .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #637080;
            color: #47c181;
        }
        .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
        .ant-menu-dark .ant-menu-item-selected .anticon {
            color: #47c181;
        }
        .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
        .ant-menu-dark .ant-menu-item-selected .anticon + span {
            color: #47c181;
        }
        .ant-menu-dark .ant-menu-item-selected > a,
        .ant-menu-dark .ant-menu-item-selected > span > a,
        .ant-menu-dark .ant-menu-item-selected > a:hover,
        .ant-menu-dark .ant-menu-item-selected > span > a:hover {
            color: #47c181;
        }
        .ant-menu-vertical .ant-menu-item,
        .ant-menu-vertical-left .ant-menu-item,
        .ant-menu-vertical-right .ant-menu-item,
        .ant-menu-inline .ant-menu-item,
        .ant-menu-vertical .ant-menu-submenu-title,
        .ant-menu-vertical-left .ant-menu-submenu-title,
        .ant-menu-vertical-right .ant-menu-submenu-title,
        .ant-menu-inline .ant-menu-submenu-title {
            margin-top: 0px;
        }
        .ant-menu-item-only-child{
            font-size: 0.95rem;
        }
        .ant-menu-item-selected{
            color: #47c181;
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;