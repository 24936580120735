.formTable .ant-table-pagination.ant-pagination {
  position: absolute;
  right: 0;
  width: 80%;
  float: right;
}
//硬件管理中的产品管理tab样式
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 16px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
  color: #fff !important;
  background: #64D399 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
  border-radius: 5px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 5px 16px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 12px 16px;
}

.bannerClass .ant-table-pagination.ant-pagination {
  margin-top: 2rem;
}
//更新历史提示框中内容文字样式
.ant-steps-item-title{
  font-weight: 500;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 0px;
}

.hardwareDevice{
  flex-wrap: wrap;
  .ant-row{
    flex-flow: row nowrap;
    // width: 220px;
  }
}
.ant-popover-inner-content {
  max-height: 445px;
  overflow-y: scroll;
}

.formTable .ant-tree-list{
  max-height: 410px;
  overflow-y: scroll;
}
@primary-color: #64D399;@border-radius-base: 5px;