.container {
    :global {
        .rc-virtual-list {
            position: relative;
            height: 100%;
            width: 70% !important;
            float: left !important;
            .rc-virtual-list-holder {
                overflow-y: inherit !important;
            }
        }
    }
}

@primary-color: #64D399;@border-radius-base: 5px;